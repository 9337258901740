import { ActionIcon } from '@mantine/core';
import { IconFilterPlus } from '@tabler/icons-react';
import React from 'react';
import { useAppLayout } from '../layout/AppLayoutProvider';

export const FiltersTabTrigger = () => {
  const { handleNavbarStatusChange } = useAppLayout();
  const onClick = () => {
    void handleNavbarStatusChange({ isOpen: true, activeTab: 'filters' });
  };
  return (
    <ActionIcon variant="subtle" color="gray" onClick={onClick} styles={{ root: { border: 'none' } }}>
      <IconFilterPlus />
    </ActionIcon>
  );
};
