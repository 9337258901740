import React from 'react';
import { Accordion, Flex, ScrollArea, Title } from '@mantine/core';
import { useElementSize } from '@mantine/hooks';
import { FoldersFilter } from './FoldersFilter';

export const SearchFilters: React.FC<{ height?: number }> = (props) => {
  const { height } = props;
  const { ref: wrapperRef, height: wrapperHeight } = useElementSize();

  return (
    <>
      <Flex
        wrap="nowrap"
        justify="space-between"
        align="center"
        p="var(--mantine-spacing-xs)"
        ref={wrapperRef}
        id="filters-tab-title"
      >
        <Title order={4}>Filters</Title>
      </Flex>
      <ScrollArea
        type="scroll"
        mt={1}
        h={`calc(100% - ${wrapperHeight}px - 1`}
        w="100%"
        scrollbarSize={0}
        offsetScrollbars
        styles={{ viewport: { width: '100%' } }}
      >
        <Accordion
          multiple
          defaultValue={['Folders']}
          styles={{
            item: { borderBottom: 'unset' },
            control: { paddingLeft: 0, paddingRight: 0 },
            content: { paddingLeft: 0, paddingRight: 0 },
          }}
        >
          <FoldersFilter />
        </Accordion>
      </ScrollArea>
    </>
  );
};
