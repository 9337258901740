import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturnType } from '@mantine/form';
import { v4 as uuidV4 } from 'uuid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  FolderListItemFragment,
  SearchInput,
  SearchTypeEnum,
  useGetFoldersListQuery,
  useSendSearchQueryMutation,
} from '../../services/graphql/apolloAppClient';
import { useQueryHistory } from '../../layout/NavbarLayout/QueryHistory';
import { MantineTreeBasicDataNodeType, parseMantineTreeData } from '../../components';
import { useFoldersStore } from '../../layout/NavbarLayout/SearchFilters/FoldersFilter';

export type SearchFiltersType = SearchInput;
export type FoldersTreeFilterNodeType = MantineTreeBasicDataNodeType &
  FolderListItemFragment & {
    children?: FoldersTreeFilterNodeType[];
  };

export interface IUseSearchStoreResult {
  sendSearchQuery: ReturnType<typeof useSendSearchQueryMutation>[0];
  sendSearchQueryStore: ReturnType<typeof useSendSearchQueryMutation>[1];
  searchForm: UseFormReturnType<SearchInput>;
  folders: ReturnType<typeof useGetFoldersListQuery>;
  onSearch: () => Promise<void>;
}

export const SearchStoreContext = createContext<IUseSearchStoreResult | undefined>(undefined);
SearchStoreContext.displayName = 'SearchStoreContext';

export const useSearchStore = (): IUseSearchStoreResult => {
  const context = useContext(SearchStoreContext);
  if (!context) {
    console.warn('useSearchStoreContext must be used within SearchStoreProvider');
  }
  return context as IUseSearchStoreResult;
};

export const SearchStoreProvider = ({ children }) => {
  const navigate = useNavigate();
  const { queryHistory } = useQueryHistory();
  const { setCallback, checkedFolders, setCheckedFolders, folders } = useFoldersStore();

  const searchForm = useForm<SearchFiltersType>({
    name: 'QuerySearchForm',
    initialValues: {
      type: SearchTypeEnum.Auto,
      query: '',
    },
    validate: { query: (value) => (value?.length < 3 ? 'error' : undefined) },
    validateInputOnBlur: false,
  });

  const [sendSearchQuery, sendSearchQueryStore] = useSendSearchQueryMutation({
    onCompleted: (res) => {
      if (res.search.historyEntry?.id) {
        queryHistory.refetch().then(() => {
          navigate(`/${res.search.historyEntry?.id}`);
        });
      }
    },
    onError: (error, clientOptions) => {
      console.warn('Search error', { error });
    },
  });
  const onSearch = async () => {
    if (!searchForm.validate().hasErrors) {
      await sendSearchQuery({
        variables: {
          input: { ...searchForm.values, id: uuidV4() },
        },
      }).catch((error) => {
        console.warn('Search error', { error });
      });
    }
  };
  useEffect(() => {
    setCallback(undefined);
    setCheckedFolders(folders?.data?.folders?.filter((r) => r.checked).map((r) => r.id) ?? []);
  }, []);

  useEffect(() => {
    searchForm.setValues((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        folders: checkedFolders as string[],
      },
    }));
  }, [checkedFolders]);

  const searchStoreContextObject: IUseSearchStoreResult = useMemo(
    () => ({
      sendSearchQuery,
      sendSearchQueryStore,
      searchForm,
      onSearch,
      folders,
    }),
    [sendSearchQueryStore, searchForm, folders],
  );

  return <SearchStoreContext.Provider value={searchStoreContextObject}>{children}</SearchStoreContext.Provider>;
};
