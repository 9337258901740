import React, { useMemo, useState } from 'react';
import { useElementSize, useLocalStorage } from '@mantine/hooks';
import { Button, Flex, Portal, Text } from '@mantine/core';
import { IconMessageChatbot, IconSearch } from '@tabler/icons-react';
import { ChatRoom } from './ChatRoom';
import { SearchResultList } from './SearchResults';
import { NewSearchStoreProvider } from './SearchResults/SearchActions/NewSearchStoreProvider';
import { useSearchHistoryEntryStore } from './SearchHistoryEntryStoreProvider';
import { SearchTypeEnum } from '../../services/graphql/apolloAppClient';

export type SearchHistoryEntrySectionType = 'chat' | 'searchResults';

export type UseSearchHistoryEntrySectionsResultType = {
  activeTab: SearchHistoryEntrySectionType;
  setActiveTab: ReturnType<typeof useLocalStorage<SearchHistoryEntrySectionType>>[1];
  withChat: boolean;
};

export const EntryTabsTrigger: React.FC<UseSearchHistoryEntrySectionsResultType> = (props) => {
  const { setActiveTab, withChat, activeTab } = props;

  return (
    <Portal target="#page-title-section">
      {withChat ? (
        <Button
          variant="filled"
          leftSection={activeTab === 'chat' ? <IconSearch /> : <IconMessageChatbot />}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            setActiveTab(activeTab === 'chat' ? 'searchResults' : 'chat');
          }}
        >
          <Text
            component="span"
            styles={{
              root: {
                fontFamily: 'Inter, sans-serif',
                fontWeight: 400,
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              },
            }}
          >
            {activeTab === 'chat' ? 'Search' : 'Chat'}
          </Text>
        </Button>
      ) : (
        <Text
          component="span"
          styles={{
            root: {
              fontFamily: 'Inter, sans-serif',
              fontWeight: 400,
              // fontSize: '1em',
              color: 'white',
              whiteSpace: 'nowrap',
              textOverflow: 'ellipsis',
            },
          }}
        >
          Smart Search
        </Text>
      )}
    </Portal>
  );
};
export const SearchHistoryEntrySections: React.FC<{ withChat: boolean; queryId: string }> = ({ withChat, queryId }) => {
  const [activeTab, setActiveTab] = useState<SearchHistoryEntrySectionType>('searchResults');
  const { ref: pageViewportRef, height: pageViewportHeight, width: pageViewportWidth } = useElementSize();

  const sectionHeight = useMemo(() => pageViewportHeight, [pageViewportHeight]);

  const SearchHistoryEntrySectionsContextObject: UseSearchHistoryEntrySectionsResultType = useMemo(
    () => ({
      activeTab,
      setActiveTab,
      withChat,
    }),
    [activeTab, withChat],
  );
  const { searchHistoryEntryStore } = useSearchHistoryEntryStore();

  const initialSearchData = useMemo(
    () => ({
      query: searchHistoryEntryStore?.data?.query?.query ?? '',
      type:
        searchHistoryEntryStore?.data?.query?.__typename === 'SemanticHistoryEntryDto'
          ? SearchTypeEnum.AiSearch
          : searchHistoryEntryStore?.data?.query?.__typename === 'KeywordHistoryEntryDto'
          ? SearchTypeEnum.KeywordSearch
          : SearchTypeEnum.Auto,
      filter: {
        folders: searchHistoryEntryStore?.data?.query?.folders,
      },
    }),
    [searchHistoryEntryStore],
  );
  return (
    <Flex ref={pageViewportRef} h="100%" gap={3} w="100%" direction="column" pos="relative">
      <EntryTabsTrigger activeTab={activeTab} setActiveTab={setActiveTab} withChat={withChat} />
      <NewSearchStoreProvider initialSearchData={initialSearchData}>
        {sectionHeight ? (
          <>
            {withChat && activeTab === 'chat' ? (
              <ChatRoom
                style={{
                  height: '100%',
                  width: '100%',
                  // position: 'absolute',
                  // transition: 'left .5s ease-out',
                  // left: activeTab === 'chat' ? '0%' : '-100%',
                }}
              />
            ) : (
              <SearchResultList
                style={{
                  height: '100%',
                  width: '100%',
                  // position: 'absolute',
                  // transition: 'left .5s ease-out',
                  // left: activeTab === 'chat' ? '100%' : '0%',
                }}
              />
            )}
          </>
        ) : null}
      </NewSearchStoreProvider>
    </Flex>
  );
};
