import React from 'react';
import { Accordion, ActionIcon, MantineColorScheme, Menu, NavLink } from '@mantine/core';
import { IconMoonStars, IconSun } from '@tabler/icons-react';
import { useAppTheme } from './AppThemeProvider';

export interface IAppThemeSwitcherProps {
  type?: 'dropdown' | 'accordion' | 'list';
}

export const CurrentThemeIcon: React.FC = () => {
  const { appColorScheme, mantineTheme: theme } = useAppTheme();
  if (appColorScheme === 'dark') {
    return <IconMoonStars />;
  }

  return <IconSun stroke={2} color={theme.colors.yellow[5]} />;
};

export const AppThemeSwitcher: React.FC<IAppThemeSwitcherProps> = (props) => {
  const { type = 'dropdown' } = props;

  const { appColorScheme, mantineColorScheme, mantineTheme: theme, setMantineColorScheme } = useAppTheme();

  const themeIcon = (colorScheme: string, size: string | number = 28) => {
    return colorScheme === 'light' ? (
      <IconSun size={size} stroke={2} color={theme.colors.yellow[5]} />
    ) : (
      <IconMoonStars size={size} />
    );
  };

  if (type === 'dropdown') {
    return (
      <Menu shadow="md" width={200}>
        <Menu.Target>
          <ActionIcon
            // size="sm"
            variant="transparent"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            {appColorScheme === 'dark' ? (
              <IconMoonStars color={theme.white} />
            ) : (
              <IconSun stroke={2} color={theme.colors.yellow[5]} />
            )}
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>Themes</Menu.Label>
          {(['light', 'dark', 'auto'] as MantineColorScheme[]).map((item, key) => (
            <Menu.Item
              key={`themes-menu-${key}`}
              leftSection={themeIcon(item, '1rem')}
              color={mantineColorScheme === item ? theme.colors.egwPrimary[5] : undefined}
              rightSection={item}
              styles={{
                item: {
                  pointerEvents: mantineColorScheme === item ? 'none' : 'all',
                },
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                if (mantineColorScheme !== item) {
                  setMantineColorScheme(item);
                }
              }}
            />
          ))}
        </Menu.Dropdown>
      </Menu>
    );
  }
  if (type === 'accordion') {
    return (
      <Accordion defaultValue="Themes">
        <Accordion.Item key="Themes" value="Themes">
          <Accordion.Control
            icon={
              appColorScheme === 'dark' ? (
                <IconMoonStars color={theme.white} />
              ) : (
                <IconSun stroke={2} color={theme.colors.yellow[5]} />
              )
            }
          >
            {mantineColorScheme}
          </Accordion.Control>
          <Accordion.Panel>
            {(['light', 'dark', 'auto'] as MantineColorScheme[]).map((item, key) => (
              <NavLink
                key={`accordion-themes-menu-${key}`}
                active={mantineColorScheme === item}
                styles={{
                  root: {
                    width: '100%',
                    pointerEvents: mantineColorScheme === item ? 'none' : 'all',
                  },
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  if (mantineColorScheme !== item) {
                    setMantineColorScheme(item);
                  }
                }}
                label={item}
                leftSection={themeIcon(item, '1rem')}
              />
            ))}
          </Accordion.Panel>
        </Accordion.Item>
      </Accordion>
    );
  }
  if (type === 'list') {
    return (
      <>
        {(['light', 'dark', 'auto'] as MantineColorScheme[]).map((item, key) => (
          <NavLink
            key={`accordion-themes-menu-${key}`}
            active={mantineColorScheme === item}
            styles={{
              root: {
                width: '100%',
                pointerEvents: mantineColorScheme === item ? 'none' : 'all',
              },
            }}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              if (mantineColorScheme !== item) {
                setMantineColorScheme(item);
              }
            }}
            label={item}
            leftSection={themeIcon(item, '1rem')}
          />
        ))}
      </>
    );
  }
  return null;
};
