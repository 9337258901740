import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { NavBarActiveTabType } from './NavbarLayout';
import { useAppTheme } from '../themes';

export type NavBarStatusType = {
  isOpen: boolean;
  activeTab: NavBarActiveTabType;
};
export interface UseAppLayoutResultType {
  navbarStatus: NavBarStatusType;
  withNavBar: boolean;
  // setNavbarStatus: ReturnType<typeof useLocalStorage<NavBarActiveTabType>>[1];
  setNavbarStatus: React.Dispatch<React.SetStateAction<NavBarStatusType>>;
  handleNavbarStatusChange: (nextState: Partial<NavBarStatusType>) => Promise<void>;
}

export const AppLayoutContext = createContext<UseAppLayoutResultType | undefined>(undefined);
AppLayoutContext.displayName = 'AppLayoutContext';

export const useAppLayout = (): UseAppLayoutResultType => {
  const context = useContext(AppLayoutContext);
  if (!context) {
    console.warn('useLayoutStoreContext must be used within LayoutStoreProvider');
  }
  return context as UseAppLayoutResultType;
};

export const AppLayoutProvider = ({ children }) => {
  const location = useLocation();
  const { isMobileLayout } = useAppTheme();

  const [navbarStatus, setNavbarStatus] = useState<NavBarStatusType>({
    isOpen: !isMobileLayout,
    activeTab: 'filters',
  });
  const [withNavBar, setWithNavBar] = useState(location.pathname !== '/auth');

  useEffect(() => {
    setWithNavBar(!['/auth', '/auth/redirect'].includes(location.pathname));
  }, [location.pathname]);

  useEffect(() => {
    // if (!isMobileLayout && !['history', 'filters', null].includes(navbarStatus.activeTab)) {
    void handleNavbarStatusChange({ isOpen: !isMobileLayout, activeTab: 'filters' });
    // }
  }, [isMobileLayout]);

  // useEffect(() => {
  //   console.log({ navbarStatus });
  // }, [navbarStatus]);

  const handleNavbarStatusChange = async (nextState: Partial<NavBarStatusType>) => {
    setNavbarStatus((prevState) => {
      return {
        ...prevState,
        ...nextState,
        isOpen: isMobileLayout ? nextState?.isOpen ?? prevState?.isOpen : true,
      };
    });
  };

  const appLayoutContextObject: UseAppLayoutResultType = useMemo(
    () => ({
      navbarStatus,
      setNavbarStatus,
      withNavBar,
      handleNavbarStatusChange,
    }),
    [navbarStatus, withNavBar],
  );

  return <AppLayoutContext.Provider value={appLayoutContextObject}>{children}</AppLayoutContext.Provider>;
};
