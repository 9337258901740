import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import {
  MantineColorScheme,
  MantineProvider,
  MantineTheme,
  useMantineColorScheme,
  useMantineTheme,
} from '@mantine/core';
import '../assets/styles/index.scss';
import { ModalsProvider } from '@mantine/modals';
import { Notifications } from '@mantine/notifications';
import { useColorScheme } from '@mantine/hooks';
import { getAppThemes } from './Themes';
import { useAppUserAgent } from '../hooks';

export interface IAppThemeContextProps {
  appColorScheme: 'light' | 'dark';
  isMobileLayout: boolean;
  mantineTheme: MantineTheme;
  setMantineColorScheme: (value: MantineColorScheme) => void;
  mantineColorScheme: MantineColorScheme;
  systemColorScheme: 'light' | 'dark';
}

export const AppThemeContext = createContext<IAppThemeContextProps | undefined>(undefined);
AppThemeContext.displayName = 'AppThemeContext';

export const useAppTheme = (): IAppThemeContextProps => {
  const context = useContext(AppThemeContext);
  if (!context) {
    console.warn('useAppTheme must be used within AppThemeProvider');
  }
  return context as IAppThemeContextProps;
};

const AppThemeProviderComponent: React.FC<PropsWithChildren<{ isMobileLayout: boolean }>> = ({
  children,
  isMobileLayout,
}) => {
  const { colorScheme: mantineColorScheme, setColorScheme: setMantineColorScheme } = useMantineColorScheme({
    keepTransitions: true,
  });
  const systemColorScheme = useColorScheme();
  const mantineTheme = useMantineTheme();

  const AppThemeContextObject: IAppThemeContextProps = useMemo(
    () => ({
      appColorScheme: mantineColorScheme === 'auto' ? systemColorScheme : mantineColorScheme,
      mantineColorScheme,
      systemColorScheme,
      isMobileLayout,
      mantineTheme,
      setMantineColorScheme,
    }),
    [mantineColorScheme, systemColorScheme, isMobileLayout, mantineTheme],
  );

  return (
    <AppThemeContext.Provider value={AppThemeContextObject}>
      <Notifications />
      <ModalsProvider>{children}</ModalsProvider>
    </AppThemeContext.Provider>
  );
};

export const AppThemeProvider = ({ children }) => {
  const { isMobileLayout } = useAppUserAgent();
  const [appTheme, setAppTheme] = useState(getAppThemes({ isMobileLayout }));

  useEffect(() => {
    setAppTheme(getAppThemes({ isMobileLayout }));
  }, [isMobileLayout]);
  return (
    <MantineProvider theme={appTheme.mantineTheme} defaultColorScheme="auto">
      <AppThemeProviderComponent isMobileLayout={isMobileLayout}>{children}</AppThemeProviderComponent>
    </MantineProvider>
  );
};
