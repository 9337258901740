import { ActionIcon, NavLink, Text } from '@mantine/core';
import React, { useState } from 'react';
import { notifications } from '@mantine/notifications';
import { NavLink as RouterNavLink, useNavigate } from 'react-router-dom';
import { modals } from '@mantine/modals';
import { IconMessageChatbot, IconSearch, IconTrash } from '@tabler/icons-react';
import { DateTimeNormalizeRender } from '../../../components';
import { useAppLayout } from '../../AppLayoutProvider';
import { QueryHistoryItemFragment, useDeleteQueryMutation } from '../../../services/graphql/apolloAppClient';
import { CONSTANTS } from '../../../config';
import { useAppTheme } from '../../../themes';

export interface QueryHistoryItemProps {
  itemData: QueryHistoryItemFragment;
  isActive: boolean;
  effectCallback?: () => Promise<any> | any;
}

export const QueryHistoryItem = (props: QueryHistoryItemProps) => {
  const { itemData, effectCallback = () => undefined, isActive = false } = props;
  const navigate = useNavigate();
  const { isMobileLayout, mantineTheme: theme } = useAppTheme();
  const { handleNavbarStatusChange } = useAppLayout();
  const [hovered, setHovered] = useState<boolean>(false);
  const [deleteQueryAction, deleteQueryActionResult] = useDeleteQueryMutation({
    onCompleted: (data, queryOptions) => {
      if (data.deleteQuery.boolean) {
        notifications.show({
          title: 'Delete query',
          message: 'Delete query from history status: true',
        });
        effectCallback();
        if (isActive) {
          navigate('/');
        }
      }
    },
    onError: (error) => {
      console.log({ error });
    },
  });

  const handleDelete = () => {
    modals.openConfirmModal({
      title: 'Please confirm query delete',
      closeOnConfirm: !deleteQueryActionResult.loading,
      centered: true,
      children: <Text size="sm">Are you sure you want to delete this query? This action is destructive.</Text>,
      labels: { confirm: 'Delete', cancel: 'Cancel' },
      confirmProps: { color: 'red', loading: deleteQueryActionResult.loading },
      onConfirm: async () => {
        await deleteQueryAction({ variables: { input: { id: itemData.id } } });
      },
    });
  };

  return (
    <NavLink
      component={RouterNavLink}
      to={`/${itemData.id}`}
      active={isActive}
      styles={{
        root: {
          width: '100%',
        },
        body: {
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          whiteSpace: 'nowrap',
          width: '100%',
        },
        label: {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          display: 'block',
          width: '100%',
        },
        description: {
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          display: 'inline',
        },
      }}
      onClick={() => {
        navigate(`/${itemData.id}`);
        void handleNavbarStatusChange({ isOpen: false, activeTab: 'filters' });
      }}
      onMouseEnter={() => {
        setHovered(true);
      }}
      onMouseLeave={() => {
        setHovered(false);
      }}
      label={itemData.query}
      description={<DateTimeNormalizeRender date={itemData.createdAt} />}
      leftSection={
        itemData.__typename === 'KeywordHistoryEntryDto' || !CONSTANTS.APP_WITH_CHAT ? (
          <IconSearch />
        ) : (
          <IconMessageChatbot />
        )
      }
      rightSection={
        hovered || isMobileLayout || deleteQueryActionResult.loading ? (
          <ActionIcon
            disabled={deleteQueryActionResult.loading}
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDelete();
            }}
            variant="subtle"
            color={theme.colors.red[5]}
            loading={deleteQueryActionResult.loading}
          >
            <IconTrash />
          </ActionIcon>
        ) : undefined
      }
    />
  );
};
