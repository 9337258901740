import { Button, Portal } from '@mantine/core';
import React from 'react';
import { useNewSearchStore } from './NewSearchStoreProvider';

export const ApplyFiltersButton: React.FC = () => {
  const { searchForm, onSearch } = useNewSearchStore();

  return (
    <Portal target="#filters-tab-title">
      <Button
        variant="transparent"
        disabled={searchForm.values?.query?.length < 3}
        onClick={() => {
          void onSearch();
        }}
      >
        Apply
      </Button>
    </Portal>
  );
};
