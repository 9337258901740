import React, { useCallback } from 'react';
import { Button, Portal, ScrollArea } from '@mantine/core';
import { IconCopy } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';
import { useSearchHistoryEntryStore } from '../SearchHistoryEntryStoreProvider';
import { CopyToClipboard } from '../../../components';

export const copySearchResultsToClipboard = async (data: { text?: string; uriLabel?: string; uri?: string }[] = []) => {
  let htmlString = '';
  data?.forEach((item, key, array) => {
    const { text, uriLabel, uri } = item;
    const href = uri ? `<a href="${uri}">${uriLabel}</a>` : `<span>${uriLabel}</span>`;
    if (array.length > 1) {
      const number = `<div style="width: 100%;text-align: center">${key + 1}. </div>`;
      const content = `<li><p>${text}<br /><br />${href}</p></li>`;
      htmlString += key === 0 ? `<ol>${content}` : array.length === key + 1 ? `${content}</ol>` : content;
    } else {
      htmlString += `<p>${text}<br /><br />${href}</p>`;
    }
  });
  try {
    await navigator?.clipboard?.write([
      new ClipboardItem({
        'text/html': new Blob([htmlString], { type: 'text/html' }),
        'text/plain': new Blob([htmlString], { type: 'text/plain' }),
      }),
    ]);

    console.log('Copied to clipboard');
  } catch (e) {
    console.warn('Could not copy');
  }
};

export const CopyAllResultsToClipboard = () => {
  const { searchHistoryEntryStore } = useSearchHistoryEntryStore();
  const handleClick = useCallback<React.MouseEventHandler<HTMLButtonElement>>(async (event) => {
    try {
      await copySearchResultsToClipboard(
        searchHistoryEntryStore?.data?.query?.searchResults?.map((i) => ({
          text: i.fullText || i.snippet,
          uriLabel: i.referenceCode,
          uri: i.uri,
        })),
      );
      const clipboard = await navigator?.clipboard?.readText();
      notifications.show({
        title: 'Result copied to clipboard',
        autoClose: false,
        message: (
          <ScrollArea.Autosize mah={150}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={{ __html: clipboard }} />
          </ScrollArea.Autosize>
        ),
      });
    } catch (e) {
      console.error({ e });
    }
  }, []);
  if (
    searchHistoryEntryStore.data?.query?.searchResults &&
    searchHistoryEntryStore.data?.query?.searchResults?.length < 1
  ) {
    return null;
  }

  return (
    <Portal target="#app-shell-main">
      <Button
        variant="default"
        leftSection={<IconCopy />}
        onClick={handleClick}
        pos="absolute"
        right="calc(var(--app-shell-aside-offset, 0rem) + var(--app-shell-padding))"
        bottom="calc(var(--app-shell-footer-offset, 0rem) + var(--app-shell-padding))"
      >
        Copy All
      </Button>
    </Portal>
  );
};
