import React from 'react';
import { Flex } from '@mantine/core';
import { SearchTypeFilter } from './SearchTypeFilter';
import { FiltersTabTrigger } from '../../../../components';
import { NewSearchInput } from './NewSearchInput';
import { NewSearchStoreProvider } from './NewSearchStoreProvider';
import { useSearchHistoryEntryStore } from '../../SearchHistoryEntryStoreProvider';
import { SearchTypeEnum } from '../../../../services/graphql/apolloAppClient';
import { ApplyFiltersButton } from './ApplyFiltersButton';

export const NewSearchSectionWrapper = () => {
  // const { searchHistoryEntryStore } = useSearchHistoryEntryStore();
  //
  // const initialSearchData = useMemo(
  //   () => ({
  //     query: searchHistoryEntryStore?.data?.query?.query ?? '',
  //     type:
  //       searchHistoryEntryStore?.data?.query?.__typename === 'SemanticHistoryEntryDto'
  //         ? SearchTypeEnum.AiSearch
  //         : searchHistoryEntryStore?.data?.query?.__typename === 'KeywordHistoryEntryDto'
  //         ? SearchTypeEnum.KeywordSearch
  //         : SearchTypeEnum.Auto,
  //     filter: {
  //       folders: searchHistoryEntryStore?.data?.query?.folders,
  //     },
  //   }),
  //   [searchHistoryEntryStore],
  // );
  return (
    // <NewSearchStoreProvider initialSearchData={initialSearchData}>
    <Flex w="100%" wrap="nowrap" direction="column" gap={3} pos="sticky">
      <ApplyFiltersButton />
      <Flex w="100%" gap={5} justify="space-between" align="center" direction="row" wrap="nowrap">
        <SearchTypeFilter />
        <FiltersTabTrigger />
      </Flex>
      <NewSearchInput />
    </Flex>
    // </NewSearchStoreProvider>
  );
};
