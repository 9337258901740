import React, { useEffect } from 'react';
import { ActionIcon, Popover, Text } from '@mantine/core';
import { IconMicrophone, IconMicrophoneOff } from '@tabler/icons-react';
import SpeechRecognition, {
  ListeningOptions,
  SpeechRecognitionOptions,
  useSpeechRecognition,
} from 'react-speech-recognition';
import { useDebouncedCallback, useDisclosure, usePrevious } from '@mantine/hooks';

export interface VoiceInputPropsType {
  disabled?: boolean;
  onInterimTranscript?: (interimTranscript: string, listening: boolean) => void;
  stopDelayOnContinuous?: number;
  onFinalTranscript?: (finalTranscript: string, listening: boolean) => void;
  onTranscript?: (transcript: string, listening: boolean) => void;
  speechRecognitionOptions?: SpeechRecognitionOptions;
  listeningOptions?: ListeningOptions;
}

export const VoiceInput: React.FC<VoiceInputPropsType> = (props) => {
  const {
    listeningOptions = { continuous: false },
    onInterimTranscript,
    onFinalTranscript,
    onTranscript,
    speechRecognitionOptions = { clearTranscriptOnListen: true },
    stopDelayOnContinuous = 5000,
    disabled = false,
  } = props;

  const {
    transcript,
    finalTranscript,
    listening,
    browserSupportsSpeechRecognition,
    interimTranscript,
    isMicrophoneAvailable,
    resetTranscript,
  } = useSpeechRecognition(speechRecognitionOptions);

  const handleSpeechRecognitionStartListening = () => {
    if (listeningOptions?.continuous) {
      resetTranscript();
    }
    void SpeechRecognition.startListening({ language: 'en-US', interimResults: true, ...listeningOptions });
  };
  const handleSpeechRecognitionStopListening = () => {
    void SpeechRecognition.stopListening();
  };

  const previousListening = usePrevious(listening);

  // const handleSpeechRecognitionContinuousListening = useDebouncedCallback(async () => {
  //   if (finalTranscript !== '' && listening) {
  //     await SpeechRecognition.stopListening()
  //       .then(() => {
  //         onFinalTranscript?.(finalTranscript);
  //       })
  //       .then(() => {
  //         resetTranscript();
  //         SpeechRecognition.abortListening();
  //       });
  //   }
  // }, stopDelayOnContinuous ?? 5000);

  // useEffect(() => {
  //   if (!disabled) {
  //     if (listeningOptions.continuous && !!stopDelayOnContinuous) {
  //       handleSpeechRecognitionContinuousListening();
  //     } else if (finalTranscript !== '') {
  //       onFinalTranscript?.(finalTranscript, listening);
  //       if (!listening) {
  //         resetTranscript();
  //       }
  //     }
  //     if (interimTranscript !== '') {
  //       onInterimTranscript?.(interimTranscript, listening);
  //     }
  //     if (transcript !== '') {
  //       if (listening) {
  //         onTranscript?.(transcript, listening);
  //       }
  //     }
  //   }
  // }, [transcript, interimTranscript, finalTranscript, listening, disabled]);

  useEffect(() => {
    if (!disabled) {
      if (transcript !== '') {
        onTranscript?.(transcript, listening);
      }
      if (interimTranscript !== '') {
        onInterimTranscript?.(interimTranscript, listening);
      }
      if (finalTranscript !== '') {
        onFinalTranscript?.(finalTranscript, listening);
      }
    }
  }, [transcript, finalTranscript, interimTranscript]);

  const IconListening = listening ? IconMicrophoneOff : IconMicrophone;

  const [opened, { close, open }] = useDisclosure(false);

  const popoverAlert = !browserSupportsSpeechRecognition
    ? 'Your browser does not support voice input'
    : !isMicrophoneAvailable
    ? 'Your microphone is unavailable, please turn it on in browses settings'
    : '';

  const openedDirectly = !!popoverAlert && opened;

  return (
    <ActionIcon
      variant="subtle"
      color={listening ? 'green' : 'gray'}
      disabled={disabled || !!popoverAlert}
      onClick={() => {
        const handler = listening ? handleSpeechRecognitionStopListening : handleSpeechRecognitionStartListening;
        handler();
      }}
    >
      <Popover width={200} position="bottom" withArrow shadow="md" opened={openedDirectly} zIndex={81}>
        <Popover.Target>
          <IconListening onMouseEnter={open} onMouseLeave={close} />
        </Popover.Target>
        <Popover.Dropdown style={{ pointerEvents: 'none' }}>
          <Text size="xs">{popoverAlert}</Text>
        </Popover.Dropdown>
      </Popover>
    </ActionIcon>
  );
};
