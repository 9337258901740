import { Box, Flex, Paper, ScrollArea } from '@mantine/core';

import React, { CSSProperties, useEffect, useRef } from 'react';
import { useElementSize } from '@mantine/hooks';
import { ChatMessage } from './ChatMessage';
import './assets/styles/chat-room.scss';
import { ChatStoreProvider, useChatStore } from './ChatStoreProvider';
import { ChatAlertSection } from './ChatAlertSection';
import { ChatInput } from './ChatInput';
import { useAppTheme } from '../../../themes';
import { CopyChatToClipboard } from './CopyChatToClipboard';

export interface ChatRoomPropsType {
  style?: CSSProperties;
}

export const ChatRoomComponent: React.FC<ChatRoomPropsType> = (props) => {
  const { style } = props;
  const { isMobileLayout } = useAppTheme();
  const { messages } = useChatStore();
  const { ref: chatViewportRef, height: chatViewportHeight } = useElementSize();
  const { ref: chatWrapperRef, height: chatWrapperHeight } = useElementSize();
  const chatListRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    chatViewportRef.current?.scrollTo({
      top: chatViewportRef.current?.scrollHeight,
      behavior: 'smooth',
    });
  }, [messages]);

  return (
    <Flex direction="column" style={style} pos="relative" ref={chatWrapperRef}>
      <ScrollArea
        component={Paper}
        // @ts-ignore
        withBorder
        style={{
          borderBottom: 'none',
          borderBottomLeftRadius: 0,
          borderBottomRightRadius: 0,
          boxShadow: 'none',
          flex: 1,
        }}
        styles={{
          viewport: {
            paddingLeft: 'var(--scrollarea-scrollbar-size)',
          },
        }}
        className="naw-section-scroll-area"
        viewportRef={chatViewportRef}
        offsetScrollbars
        scrollbarSize={isMobileLayout ? 0 : undefined}
      >
        {chatViewportHeight > 0 && (
          <Flex
            ref={chatListRef}
            p={3}
            style={{
              minHeight: chatViewportHeight,
            }}
            direction="column"
            justify="flex-end"
            w="100%"
            gap={10}
          >
            {messages.map((msg, i) => {
              return <ChatMessage key={msg.id} message={msg} />;
            })}
          </Flex>
        )}
      </ScrollArea>
      <CopyChatToClipboard bottom={chatWrapperHeight - chatViewportHeight} />
      <Box
        style={{
          display: style?.display === 'none' ? 'none' : 'block',
          width: '100%',
          alignItems: 'center',
          backgroundColor: 'var(--mantine-color-body)',
        }}
      >
        <ChatAlertSection />
        <ChatInput />
      </Box>
    </Flex>
  );
};

export const ChatRoom: React.FC<ChatRoomPropsType> = (props) => {
  return (
    <ChatStoreProvider>
      <ChatRoomComponent {...props} />
    </ChatStoreProvider>
  );
};
