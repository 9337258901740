import { Flex, Notification, ScrollArea } from '@mantine/core';
import React, { CSSProperties } from 'react';
import { IconAlertCircle } from '@tabler/icons-react';
import { useElementSize } from '@mantine/hooks';
import { useSearchHistoryEntryStore } from '../SearchHistoryEntryStoreProvider';
import { SearchResultListItem } from './SearchResultListItem';
import { NewSearchSectionWrapper, RephraseButtons } from './SearchActions';
import { useAppTheme } from '../../../themes';
import { CopyAllResultsToClipboard } from './CopyAllResultsToClipboard';

export const SearchResultList: React.FC<{ style?: CSSProperties }> = (props) => {
  const { style } = props;
  const { isMobileLayout } = useAppTheme();
  const { searchHistoryEntryStore } = useSearchHistoryEntryStore();
  const { ref: listViewportRef, height: listViewportHeight } = useElementSize();
  return (
    <Flex direction="column" h="100%" style={style}>
      <NewSearchSectionWrapper />
      <ScrollArea
        flex={1}
        className="naw-section-scroll-area"
        viewportRef={listViewportRef}
        styles={{
          viewport: {
            paddingLeft: 'var(--scrollarea-scrollbar-size)',
          },
        }}
        offsetScrollbars
        scrollbarSize={isMobileLayout ? 0 : undefined}
        id="search-result-list-affix-wrapper-ref"
        pos="relative"
        pt={3}
      >
        {listViewportHeight > 0 && (
          <Flex direction="column" justify="flex-start" w="100%" gap={10}>
            {searchHistoryEntryStore.data?.query?.searchResults &&
            searchHistoryEntryStore.data?.query?.searchResults?.length > 0 ? (
              <>
                {searchHistoryEntryStore.data?.query?.searchResults?.map((q, i) => {
                  return <SearchResultListItem key={`q-${i}`} content={q} />;
                })}
                <CopyAllResultsToClipboard />
              </>
            ) : (
              <Notification icon={<IconAlertCircle />} withCloseButton={false}>
                No search results for your query. Please <RephraseButtons />.
              </Notification>
            )}
          </Flex>
        )}
      </ScrollArea>
    </Flex>
  );
};
