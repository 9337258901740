import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useForm, UseFormReturnType } from '@mantine/form';
import { v4 as uuidV4 } from 'uuid';
import { useDebouncedCallback } from '@mantine/hooks';
import { ApolloError } from '@apollo/client';
import {
  SearchInput,
  SearchTypeEnum,
  useGetFoldersListQuery,
  useGetSelectedQueryHistoryItemQuery,
  useSendSearchQueryMutation,
} from '../../services/graphql/apolloAppClient';
import { useQueryHistory } from '../../layout/NavbarLayout/QueryHistory';
import { useFoldersStore } from '../../layout/NavbarLayout/SearchFilters/FoldersFilter';
import { useAppLayout } from '../../layout/AppLayoutProvider';
import { apolloErrorsHandler } from '../../utils';

// export type SearchHistoryEntryFiltersType = SearchInput;

export type IUseSearchHistoryEntryStoreResult = {
  searchHistoryEntryStore: ReturnType<typeof useGetSelectedQueryHistoryItemQuery>;
  // sendSearchQuery: ReturnType<typeof useSendSearchQueryMutation>[0];
  // sendSearchQueryStore: ReturnType<typeof useSendSearchQueryMutation>[1];
  // searchForm: UseFormReturnType<SearchHistoryEntryFiltersType>;
  // folders: ReturnType<typeof useGetFoldersListQuery>;
  // onSearch: (...args: any[]) => void;
};

export const SearchHistoryEntryStoreContext = createContext<IUseSearchHistoryEntryStoreResult | undefined>(undefined);
SearchHistoryEntryStoreContext.displayName = 'SearchHistoryEntryStoreContext';

export const useSearchHistoryEntryStore = (): IUseSearchHistoryEntryStoreResult => {
  const context = useContext(SearchHistoryEntryStoreContext);
  if (!context) {
    throw new Error('useSearchHistoryEntryStoreContext must be used within SearchHistoryEntryStoreProvider');
  }
  return context as IUseSearchHistoryEntryStoreResult;
};

export const SearchHistoryEntryStoreProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const { historyId } = useParams<{ historyId: string }>();
  const navigate = useNavigate();

  // const { setCallback, checkedFolders, setCheckedFolders, folders } = useFoldersStore();
  //
  const searchHistoryEntryStore = useGetSelectedQueryHistoryItemQuery({
    variables: {
      id: historyId,
    },
    fetchPolicy: 'network-only',
    onCompleted: (newQueryData) => {
      if (!newQueryData?.query) {
        navigate('/');
      }
      // const newFormInitialValues = {
      //   query: newQueryData?.query?.query ?? '',
      //   type:
      //     newQueryData?.query?.__typename === 'SemanticHistoryEntryDto'
      //       ? SearchTypeEnum.AiSearch
      //       : newQueryData?.query?.__typename === 'KeywordHistoryEntryDto'
      //       ? SearchTypeEnum.KeywordSearch
      //       : SearchTypeEnum.Auto,
      //   filter: {
      //     folders: newQueryData?.query?.folders
      //       ? [...newQueryData.query.folders]
      //       : folders?.data?.folders?.filter((r) => r.checked).map((r) => r.id),
      //   },
      // };
      // searchForm.setValues(newFormInitialValues);
      // searchForm.resetDirty(newFormInitialValues);
      // setCheckedFolders(
      //   newQueryData?.query?.folders
      //     ? [...newQueryData.query.folders]
      //     : folders?.data?.folders?.filter((r) => r.checked).map((r) => r.id) ?? [],
      // );
      // setCallback(
      //   () => (checkedFolders) =>
      //     onSearch({
      //       filter: { folders: checkedFolders as string[] },
      //       query: '',
      //     }),
      // );
    },
    onError: (error) => {
      navigate('/');
      console.warn('Search History Entry Error', { error });
    },
    skip: !historyId,
  });

  // const searchForm = useForm<SearchHistoryEntryFiltersType>({
  //   name: 'HistoryEntrySearchForm',
  //   initialValues: {
  //     type: SearchTypeEnum.Auto,
  //     query: '',
  //     filter: {
  //       folders: [...(checkedFolders as string[])],
  //     },
  //   },
  //   validateInputOnBlur: false,
  // });
  //
  // const [sendSearchQuery, sendSearchQueryStore] = useSendSearchQueryMutation({
  //   onCompleted: (res) => {
  //     if (res.search.historyEntry?.id) {
  //       queryHistory.refetch().then(() => {
  //         // toggleSider();
  //         navigate(`/${res.search.historyEntry?.id}`);
  //       });
  //       setCallback(undefined);
  //     }
  //   },
  //   onError: (error, clientOptions) => {
  //     console.warn('Search error', { error });
  //   },
  // });
  //
  // const onSearch = useDebouncedCallback(async (values: SearchHistoryEntryFiltersType) => {
  //   if (searchHistoryEntryStore.data?.query?.query && !searchForm.validate().hasErrors) {
  //     await sendSearchQuery({
  //       variables: {
  //         input: {
  //           ...values,
  //           query: searchHistoryEntryStore.data?.query?.query,
  //           id: uuidV4(),
  //           type:
  //             searchHistoryEntryStore.data?.query?.__typename === 'SemanticHistoryEntryDto'
  //               ? SearchTypeEnum.AiSearch
  //               : searchHistoryEntryStore.data?.query?.__typename === 'KeywordHistoryEntryDto'
  //               ? SearchTypeEnum.KeywordSearch
  //               : SearchTypeEnum.Auto,
  //         },
  //       },
  //     }).catch((error) => {
  //       console.warn('Search error', { error });
  //     });
  //   }
  // }, 2000);

  // useEffect(() => {
  //   searchForm.setValues((prevState) => ({
  //     ...prevState,
  //     filter: {
  //       ...prevState?.filter,
  //       folders: checkedFolders as string[],
  //     },
  //   }));
  // }, [checkedFolders]);

  const SearchHistoryEntryStoreContextObject: IUseSearchHistoryEntryStoreResult = useMemo(
    () => ({
      searchHistoryEntryStore,
      // searchForm,
      // folders,
      // onSearch,
      // sendSearchQuery,
      // sendSearchQueryStore,
    }),
    [
      searchHistoryEntryStore,
      // searchForm,
      // folders,
      // sendSearchQueryStore
    ],
  );

  return (
    <SearchHistoryEntryStoreContext.Provider value={SearchHistoryEntryStoreContextObject}>
      {children}
    </SearchHistoryEntryStoreContext.Provider>
  );
};
