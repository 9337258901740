import { useEffect, useMemo, useState } from 'react';
import { useOs, useViewportSize } from '@mantine/hooks';

export type OS = 'undetermined' | 'macos' | 'ios' | 'windows' | 'android' | 'linux';

function getOS(userAgent: string = window?.navigator?.userAgent): OS {
  if (typeof userAgent === 'undefined') {
    return 'undetermined';
  }

  const macosPlatforms = /(Macintosh)|(MacIntel)|(MacPPC)|(Mac68K)/i;
  const windowsPlatforms = /(Win32)|(Win64)|(Windows)|(WinCE)/i;
  const iosPlatforms = /(iPhone)|(iPad)|(iPod)/i;
  if (macosPlatforms.test(userAgent)) {
    // if (navigator.maxTouchPoints && navigator.maxTouchPoints > 0) {
    //   return 'ios';
    // }
    return 'macos';
  }
  if (iosPlatforms.test(userAgent)) {
    return 'ios';
  }
  if (windowsPlatforms.test(userAgent)) {
    return 'windows';
  }
  if (/Android/i.test(userAgent)) {
    return 'android';
  }
  if (/Linux/i.test(userAgent)) {
    return 'linux';
  }

  return 'undetermined';
}

interface UseAppUserAgentProps {
  getValueInEffect?: boolean;
  targetWidth?: number;
}

interface UseAppUserAgentResult {
  os: OS;
  isMobileLayout: boolean;
  // isTargetWidthLayout: boolean;
}

const getIsMobileLayout = (os: OS): boolean => ['android', 'ios'].includes(os);
const getIsTargetWidthLayout = (viewportWidth: number, targetWidth: number): boolean => viewportWidth <= targetWidth;

export function useAppUserAgent(props: UseAppUserAgentProps = {}): UseAppUserAgentResult {
  const { getValueInEffect = true, targetWidth = 768 } = props;
  const { width: viewportWidth } = useViewportSize();
  const [os, setOs] = useState<OS>(getValueInEffect ? 'undetermined' : getOS());
  const [isMobileLayout, setIsMobileLayout] = useState<boolean>(getIsMobileLayout(os));

  useEffect(() => {
    const newOs = getOS(window?.navigator?.userAgent);
    const newIsMobileLayout = getIsMobileLayout(newOs);
    const willUpdate = os !== newOs && isMobileLayout !== newIsMobileLayout;
    if (getValueInEffect && willUpdate) {
      setOs(newOs);
      setIsMobileLayout(newIsMobileLayout);
    }
  }, [window?.navigator?.userAgent]);

  useEffect(() => {
    const newTargetWidthLayout = getIsTargetWidthLayout(viewportWidth, targetWidth);
    const willUpdate = isMobileLayout !== newTargetWidthLayout;
    if (willUpdate) {
      setIsMobileLayout(newTargetWidthLayout);
    }
  }, [viewportWidth]);

  return useMemo(() => ({ os, isMobileLayout }), [os, isMobileLayout]);
}
