import React from 'react';
import { ActionIcon, Burger, Portal } from '@mantine/core';
import { IconLayoutSidebarLeftCollapse, IconLayoutSidebarLeftExpand } from '@tabler/icons-react';
import { useAppLayout } from '../AppLayoutProvider';
import { useAppTheme } from '../../themes';

export const NavbarBurger = () => {
  const { isMobileLayout } = useAppTheme();
  const { navbarStatus, handleNavbarStatusChange, withNavBar } = useAppLayout();
  const label = !navbarStatus ? 'Close navigation' : 'Open navigation';
  if (!withNavBar) {
    return null;
  }
  return (
    <>
      {isMobileLayout && (
        <Portal target="#header-left-actions--portal-wrapper">
          <ActionIcon
            variant="subtle"
            styles={{
              root: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
              },
            }}
            // size="sm"
            color="white"
            onClick={() => handleNavbarStatusChange({ isOpen: !navbarStatus.isOpen, activeTab: 'filters' })}
            aria-label={label}
          >
            {navbarStatus.isOpen ? <IconLayoutSidebarLeftCollapse /> : <IconLayoutSidebarLeftExpand />}
          </ActionIcon>
        </Portal>
      )}
    </>
  );
};
