import { Flex, Portal, Title } from '@mantine/core';
import React from 'react';
import { useAppTheme } from '../../themes';

export const SearchPageTitle = () => {
  const { isMobileLayout } = useAppTheme();
  return (
    <>
      {!isMobileLayout && (
        <Portal target="#page-title-section">
          <Title
            size="sm"
            order={4}
            styles={{
              root: {
                fontFamily: 'Inter, sans-serif',
                fontWeight: 400,
                // fontSize: '1em',
                color: 'white',
                whiteSpace: 'nowrap',
                textOverflow: 'ellipsis',
              },
            }}
          >
            Smart search
          </Title>
        </Portal>
      )}
    </>
  );
};
