import React, { useEffect, useState } from 'react';
import { Text } from '@mantine/core';
import { useInterval } from '@mantine/hooks';

export type InlineDotsLoaderPropsType = {
  delay?: number;
};

export const InlineDotsLoader: React.FC<InlineDotsLoaderPropsType> = (props) => {
  const { delay = 300 } = props;

  const [loader, setLoader] = useState<string>('');
  const interval = useInterval(
    () =>
      setLoader((loader) => {
        if (loader.length === 3) {
          return '';
        }
        return `${loader}.`;
      }),
    delay,
  );

  useEffect(() => {
    interval.start();
    return interval.stop;
  }, []);

  return <Text component="span">{loader}</Text>;
};
