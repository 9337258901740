import React, { useState } from 'react';
import { ActionIcon, Divider, Group, Kbd, Popover, Text, Textarea } from '@mantine/core';
import { IconFilterPlus, IconSearch, IconSend2, IconX } from '@tabler/icons-react';
import { useDisclosure, useFocusTrap, useUncontrolled } from '@mantine/hooks';
import { GetInputPropsReturnType } from '@mantine/form/lib/types';
import { useAppLayout } from '../layout/AppLayoutProvider';
import { VoiceInput } from './VoiceInput';
import { useAppTheme } from '../themes';

const InputHelper = () => {
  // const { handleNavbarStatusChange } = useAppLayout();
  // const onClick = () => {
  //   void handleNavbarStatusChange({ isOpen: true, activeTab: 'filters' });
  // };

  const [opened, { close, open }] = useDisclosure(false);
  return (
    <Popover width={200} position="bottom" withArrow shadow="md" opened={opened}>
      <Popover.Target>
        {/* <ActionIcon */}
        {/*  variant="subtle" */}
        {/*  color="gray" */}
        {/*  onMouseEnter={open} */}
        {/*  onMouseLeave={close} */}
        {/*  // onClick={onClick} */}
        {/*  styles={{ root: { border: 'none', cursor: 'default', } }} */}
        {/* > */}
        {/* <IconFilterPlus /> */}
        <IconSearch onMouseEnter={open} onMouseLeave={close} />
        {/* </ActionIcon> */}
      </Popover.Target>
      <Popover.Dropdown style={{ pointerEvents: 'none' }}>
        <Text size="xs">Keyboard events:</Text>
        <Text size="xs">
          Send message - <Kbd size="xs">Enter</Kbd>
        </Text>
        <Text size="xs">
          Add new line - <Kbd size="xs">Shift</Kbd>+<Kbd size="xs">Enter</Kbd>
        </Text>
      </Popover.Dropdown>
    </Popover>
  );
};

export interface SearchInputPropsType extends GetInputPropsReturnType {
  placeholder?: string;
  onSpeechRecognitionChange?: (value: string) => void;
  onInputSubmit?: (value: string) => void;
  disabled?: boolean;
  autoFocus?: boolean;
  loading?: boolean;
  withFilterTrigger?: boolean;
  inputBorderRadius?: {
    borderTopLeftRadius?: number;
    borderTopRightRadius?: number;
    borderBottomLeftRadius?: number;
    borderBottomRightRadius?: number;
  };
}

export const SearchInput = (props: SearchInputPropsType) => {
  const {
    onSpeechRecognitionChange,
    loading,
    onInputSubmit,
    value = '',
    defaultValue = '',
    placeholder,
    onChange,
    withFilterTrigger = false,
    inputBorderRadius,
    disabled = false,
    autoFocus = true,
    ...restInputProps
  } = props;
  const [_value, handleChange] = useUncontrolled<string>({
    value,
    defaultValue,
    finalValue: '',
    onChange: (value) => {
      onChange?.(value);
    },
  });
  const [isListening, setIsListening] = useState(false);
  const handleInputSubmit = () => {
    onInputSubmit?.(_value);
  };
  const { isMobileLayout } = useAppTheme();
  const focusTrapRef = useFocusTrap(!isMobileLayout && autoFocus);

  return (
    <Textarea
      w="100%"
      ref={focusTrapRef}
      placeholder={placeholder}
      minRows={1}
      maxRows={4}
      {...restInputProps}
      autoFocus={!isMobileLayout}
      onFocus={(e) => {
        const { value } = e.target;
        e?.target?.setSelectionRange(value.length, value.length);
      }}
      disabled={disabled}
      autosize
      styles={{
        wrapper: {
          width: '100%',
        },
        input: {
          msOverflowStyle: 'none' /* Internet Explorer 10+ */,
          scrollbarWidth: 'none' /* Firefox */,
          WebkitScrollbar: { display: 'none' },
          ...inputBorderRadius,
        },
        section: {
          flexDirection: 'column',
          justifyContent: 'end',
          bottom: 'var(--input-padding-y, 0rem)',
          top: 'unset',
        },
      }}
      leftSectionPointerEvents="all"
      leftSectionWidth="calc(var(--input-height) * 1)"
      leftSection={
        <Group justify="center" wrap="nowrap" w="100%" gap={5}>
          <InputHelper />
        </Group>
      }
      rightSectionPointerEvents="all"
      rightSectionWidth={`calc(var(--input-height) * ${_value ? 3 : 2})`}
      rightSection={
        <Group justify="center" wrap="nowrap" w="100%" gap={5}>
          {_value && (
            <>
              <ActionIcon
                variant="subtle"
                color="gray"
                disabled={disabled || loading}
                styles={{ root: { border: 'none' } }}
                onClick={() => {
                  handleChange('');
                }}
              >
                <IconX />
              </ActionIcon>
              <Divider orientation="vertical" />
            </>
          )}
          <VoiceInput
            disabled={disabled}
            onTranscript={(transcript, listening) => {
              handleChange(transcript);
              if (isListening !== listening) {
                setIsListening(listening);
              }
            }}
            onFinalTranscript={(finalTranscript, listening) => {
              handleChange(finalTranscript);
              if (isListening !== listening) {
                setIsListening(listening);
              }
              if (!listening) {
                onSpeechRecognitionChange?.(value);
              }
            }}
          />
          <ActionIcon
            variant="subtle"
            disabled={disabled || (_value as string)?.replace(/\r\n|\r|\n/g, '').length < 3}
            loading={loading}
            styles={{ root: { border: 'none' } }}
            onClick={() => {
              void handleInputSubmit();
            }}
          >
            <IconSend2 />
          </ActionIcon>
        </Group>
      }
      onKeyDown={(e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
          e.preventDefault();

          if ((_value as string)?.replace(/\r\n|\r|\n/g, '').length >= 3) {
            void handleInputSubmit();
          }
        }
      }}
      value={_value}
      onChange={(event) => handleChange(event.currentTarget.value)}
    />
  );
};
