import React from 'react';
import { Flex } from '@mantine/core';
import { SearchInput } from './SearchInput';
import { FiltersTabTrigger } from '../../../components';
import { SearchTypeFilter } from './SearchTypeFilter';
import { ApplyFiltersButton } from './ApplyFiltersButton';

export const SearchSectionWrapper = () => {
  return (
    <Flex w="100%" wrap="nowrap" direction="column" gap={3} pos="sticky">
      <ApplyFiltersButton />
      <Flex w="100%" gap={5} justify="space-between" align="center" direction="row" wrap="nowrap">
        <SearchTypeFilter />
        <FiltersTabTrigger />
      </Flex>
      <SearchInput />
    </Flex>
  );
};
