import React from 'react';
import { Flex, Notification } from '@mantine/core';
import { IconAlertCircle } from '@tabler/icons-react';
import { SearchStoreProvider } from './SearchStoreProvider';
import { useTitle } from '../../hooks/useTitle';
import { SearchPageTitle } from './SearchPageTitle';
import { SearchSectionWrapper } from './SearchActions';

export const SearchSectionComponent = () => {
  useTitle();
  return (
    <Flex direction="column" gap="md">
      <SearchPageTitle />
      <SearchSectionWrapper />
      <Notification icon={<IconAlertCircle />} withCloseButton={false} style={{ boxShadow: 'none' }}>
        Ask EGW Writings a question or enter keywords. Note that Semantic search makes mistakes, so consider refining
        your question. Please check all listed references for a complete context to your question.
      </Notification>
    </Flex>
  );
};

export const SearchPage = () => {
  return (
    <SearchStoreProvider>
      <SearchSectionComponent />
    </SearchStoreProvider>
  );
};
