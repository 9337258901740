import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useState } from 'react';
import { useForm, UseFormReturnType } from '@mantine/form';
import { v4 as uuidV4 } from 'uuid';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  FolderListItemFragment,
  SearchInput,
  SearchTypeEnum,
  useGetFoldersListQuery,
  useSendSearchQueryMutation,
} from '../../../../services/graphql/apolloAppClient';
import { useQueryHistory } from '../../../../layout/NavbarLayout/QueryHistory';
import { MantineTreeBasicDataNodeType } from '../../../../components';
import { useFoldersStore } from '../../../../layout/NavbarLayout/SearchFilters/FoldersFilter';

export type FoldersTreeFilterNodeType = MantineTreeBasicDataNodeType &
  FolderListItemFragment & {
    children?: FoldersTreeFilterNodeType[];
  };

export interface IUseNewSearchStoreResult {
  sendSearchQuery: ReturnType<typeof useSendSearchQueryMutation>[0];
  sendSearchQueryStore: ReturnType<typeof useSendSearchQueryMutation>[1];
  searchForm: UseFormReturnType<SearchInput>;
  folders: ReturnType<typeof useGetFoldersListQuery>;
  onSearch: () => Promise<void>;
}

export const NewSearchStoreContext = createContext<IUseNewSearchStoreResult | undefined>(undefined);
NewSearchStoreContext.displayName = 'NewSearchStoreContext';

export const useNewSearchStore = (): IUseNewSearchStoreResult => {
  const context = useContext(NewSearchStoreContext);
  if (!context) {
    console.warn('useNewSearchStoreContext must be used within NewSearchStoreProvider');
  }
  return context as IUseNewSearchStoreResult;
};

export type NewSearchStoreProviderPropsType = PropsWithChildren<{
  initialSearchData: SearchInput;
}>;
export const NewSearchStoreProvider: React.FC<NewSearchStoreProviderPropsType> = (props) => {
  const { children, initialSearchData } = props;
  const navigate = useNavigate();
  const { queryHistory } = useQueryHistory();
  const { setCallback, checkedFolders, setCheckedFolders, folders } = useFoldersStore();

  const searchForm = useForm<SearchInput>({
    name: 'NewQuerySearchForm',
    initialValues: {
      ...initialSearchData,
      filter: {
        folders: initialSearchData?.filter?.folders
          ? [...initialSearchData.filter.folders]
          : folders?.data?.folders?.filter((r) => r.checked).map((r) => r.id),
      },
    },
    validate: { query: (value) => (value?.length < 3 ? 'error' : undefined) },
    validateInputOnBlur: false,
  });

  const [sendSearchQuery, sendSearchQueryStore] = useSendSearchQueryMutation({
    onCompleted: (res) => {
      if (res.search.historyEntry?.id) {
        queryHistory.refetch().then(() => {
          navigate(`/${res.search.historyEntry?.id}`);
        });
      }
    },
    onError: (error, clientOptions) => {
      console.warn('Search error', { error });
    },
  });
  const onSearch = async () => {
    if (!searchForm.validate().hasErrors) {
      await sendSearchQuery({
        variables: {
          input: { ...searchForm.values, id: uuidV4() },
        },
      }).catch((error) => {
        console.warn('Search error', { error });
      });
    }
  };
  useEffect(() => {
    setCallback(undefined);
    setCheckedFolders(
      initialSearchData?.filter?.folders
        ? [...initialSearchData.filter.folders]
        : folders?.data?.folders?.filter((r) => r.checked)?.map((r) => r.id) ?? [],
    );
  }, []);

  useEffect(() => {
    searchForm.setValues((prevState) => ({
      ...prevState,
      filter: {
        ...prevState?.filter,
        folders: checkedFolders as string[],
      },
    }));
  }, [checkedFolders]);

  const NewSearchStoreContextObject: IUseNewSearchStoreResult = useMemo(
    () => ({
      sendSearchQuery,
      sendSearchQueryStore,
      searchForm,
      onSearch,
      folders,
    }),
    [sendSearchQueryStore, searchForm, folders],
  );

  return (
    <NewSearchStoreContext.Provider value={NewSearchStoreContextObject}>{children}</NewSearchStoreContext.Provider>
  );
};
