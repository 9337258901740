import { Chip, Flex, Group } from '@mantine/core';
import React, { useState } from 'react';
import { useSearchHistoryEntryStore } from '../../SearchHistoryEntryStoreProvider';
import { SearchTypeEnum } from '../../../../services/graphql/apolloAppClient';
import { useNewSearchStore } from './NewSearchStoreProvider';

export const SearchTypeFilter = () => {
  const { searchForm } = useNewSearchStore();

  const settings = [
    { value: SearchTypeEnum.Auto, title: 'Auto' },
    { value: SearchTypeEnum.AiSearch, title: 'Q&A Search' },
    { value: SearchTypeEnum.KeywordSearch, title: 'Keywords' },
  ];

  return (
    <Flex gap={3} justify="center" align="center" direction="row" wrap="wrap">
      {settings.map((s, i) => (
        <Chip
          key={`settingsChip-${i}`}
          radius={6}
          styles={{
            label: {
              paddingInlineStart: 5,
              paddingInlineEnd: 5,
            },
          }}
          checked={searchForm.values.type === s.value}
          variant="light"
          value={s.value}
          onChange={(checked) => {
            if (checked) {
              searchForm.setFieldValue('type', checked ? s.value : undefined);
            }
          }}
        >
          {s.title}
        </Chip>
      ))}
    </Flex>
  );
};
