import React from 'react';
import { Outlet } from 'react-router-dom';
import { AppShell, Container } from '@mantine/core';
import { QueryHistoryProvider } from './NavbarLayout/QueryHistory';
import { useAppTheme } from '../themes';
import { FoldersStoreProvider } from './NavbarLayout/SearchFilters/FoldersFilter';
import { NavbarLayout } from './NavbarLayout';

const MainLayoutComponent = () => {
  return (
    <>
      <NavbarLayout />
      <AppShell.Main id="app-shell-main" pos="relative">
        <Container h="100%" maw={900} p={0}>
          <Outlet />
        </Container>
      </AppShell.Main>
    </>
  );
};

export const MainLayout = () => {
  return (
    <QueryHistoryProvider>
      <FoldersStoreProvider>
        <MainLayoutComponent />
      </FoldersStoreProvider>
    </QueryHistoryProvider>
  );
};
