import React, { useEffect } from 'react';
import { AppShell, Tabs } from '@mantine/core';
import { IconFilterPlus, IconHistory } from '@tabler/icons-react';
import { useElementSize } from '@mantine/hooks';
import { QueryHistory } from './QueryHistory';
import { FooterLayout } from '../FooterLayout';
import './assets/styles/naw.scss';
import { NavbarBurger } from './NavbarBurger';
import { SearchFilters } from './SearchFilters';
import { NavBarStatusType, useAppLayout } from '../AppLayoutProvider';
import { AppThemeSwitcher, CurrentThemeIcon, useAppTheme } from '../../themes';
import { LoginSection, UserAvatar } from '../../components';

export type NavBarActiveTabType = 'filters' | 'history' | 'user' | 'theme' | null;

export const NavbarLayout = () => {
  const { ref: firstSectionRef, height: firstSectionHeight } = useElementSize();
  const { ref: lastSectionRef, height: lastSectionHeight } = useElementSize();
  const { ref: tabListRef, width: tabListWidth } = useElementSize();
  const { navbarStatus, handleNavbarStatusChange } = useAppLayout();
  const { isMobileLayout } = useAppTheme();

  return (
    <AppShell.Navbar style={{ overflow: 'hidden' }}>
      <NavbarBurger />
      <AppShell.Section ref={firstSectionRef} id="new-chat-button-navbar--portal-wrapper" />
      <AppShell.Section grow w="100%" h={`calc(100% - ${firstSectionHeight}px - ${lastSectionHeight}px`}>
        <Tabs
          defaultValue="filters"
          value={navbarStatus.activeTab || 'filters'}
          onChange={(value) => {
            void handleNavbarStatusChange({ activeTab: value as NavBarStatusType['activeTab'] });
          }}
          orientation="vertical"
          styles={{
            root: {
              width: '100%',
              height: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'nowrap',
            },
            tab: {
              padding: 'var(--mantine-spacing-sm)',
            },
            panel: {
              width: `calc(100% - ${tabListWidth}px)`,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              padding: 5,
            },
          }}
        >
          <Tabs.List ref={tabListRef} id="nawbar-tabs-list-wrapper">
            <Tabs.Tab value="filters" leftSection={<IconFilterPlus />} aria-label="Filters" />
            <Tabs.Tab value="history" leftSection={<IconHistory />} aria-label="History" />
            {isMobileLayout && (
              <>
                <Tabs.Tab mt="auto" value="theme" leftSection={<CurrentThemeIcon />} aria-label="Theme" />
                <Tabs.Tab value="user" leftSection={<UserAvatar />} aria-label="User" />
              </>
            )}
          </Tabs.List>
          <Tabs.Panel value="filters" title="Filters">
            <SearchFilters />
          </Tabs.Panel>
          <Tabs.Panel value="history" title="History">
            <QueryHistory />
          </Tabs.Panel>
          {isMobileLayout && (
            <>
              <Tabs.Panel value="theme" title="Theme">
                <AppThemeSwitcher type="list" />
              </Tabs.Panel>
              <Tabs.Panel value="user" title="User">
                <LoginSection type="list" />
              </Tabs.Panel>
            </>
          )}
        </Tabs>
      </AppShell.Section>
      <AppShell.Section style={{ width: '100%' }} ref={lastSectionRef}>
        <FooterLayout isNavbar />
      </AppShell.Section>
    </AppShell.Navbar>
  );
};
