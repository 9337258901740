import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { jwtDecode } from 'jwt-decode';
import { useLocalStorage, usePrevious } from '@mantine/hooks';
import { Log, User, WebStorageStateStore } from '../components/ReactOidcContext/OidcClientTs';
import { AuthContextProps, AuthProvider, AuthProviderProps, useAuth } from '../components';
import { CONSTANTS } from '../config';
import { getGravatar } from '../utils';

export const withSearchAccessRole = (role?: string | string[]): boolean => {
  if (CONSTANTS.APP_AUTH_ROLE_REQUIRED === undefined) {
    return true;
  }
  if (!role) {
    return false;
  }
  if (Array.isArray(role)) {
    return role.includes(CONSTANTS.APP_AUTH_ROLE_REQUIRED);
  }
  return role === CONSTANTS.APP_AUTH_ROLE_REQUIRED;
};

export type LegacyUserInfoType =
  | {
      id: number;
      username: string;
      email: string;
      name: string;
      first_name: string;
      last_name: string;
      is_superuser?: boolean;
      role?: string | string[];
      external_auth: string | string[];
      picture: string;
    }
  | null
  | undefined;

export const oidcConfig: AuthProviderProps = {
  authority: CONSTANTS.APP_API_SESSION_ACCESS_URL,
  client_id: CONSTANTS.APP_API_CLIENT_ID,
  redirect_uri: CONSTANTS.APP_ROOT_URL_ENDPOINT
    ? `${window.location.origin}/${CONSTANTS.APP_ROOT_URL_ENDPOINT}`
    : `${CONSTANTS.APP_ROOT_URL}`,
  scope: CONSTANTS.APP_API_SESSION_ACCESS_SCOPES,
  post_logout_redirect_uri: '/',
  userStore: new WebStorageStateStore({
    store: window.localStorage,
  }),
  // filterProtocolClaims: ['nbf', 'jti', 'auth_time', 'nonce', 'acr', 'amr', 'azp', 'at_hash', 'legacyUserInfo', 'role'],
  filterProtocolClaims: false,
  automaticSilentRenew: true,
  customSignInRequestUrlCallBack: (parsedUrl) => {
    const authParams = `/connect/authorize/${parsedUrl.search}`;

    const parsedUrlNext = new URL(`${CONSTANTS.APP_API_SESSION_ACCESS_URL}/accounts/login/`);
    parsedUrlNext.searchParams.append('next', authParams);
    return parsedUrlNext;
  },
  metadata: {
    authorization_endpoint: `${CONSTANTS.APP_API_SESSION_ACCESS_URL}/accounts/login/`,
    token_endpoint: `${CONSTANTS.APP_API_SESSION_ACCESS_URL}/connect/token`,
    // userinfo_endpoint: 'https://cpanel.alpha.egwhite.net/connect/userinfo',
    // jwks_uri: 'https://cpanel.alpha.egwhite.net/.well-known/jwks',
  },
};

export type AppAuthContextPropsType = AuthContextProps & {
  legacyUserInfo: ReturnType<typeof useLocalStorage<LegacyUserInfoType>>[0];
};

export const AppAuthContext = createContext<AppAuthContextPropsType | undefined>(undefined);
AppAuthContext.displayName = 'AppAuthContext';

export const useAppAuth = (): AppAuthContextPropsType => {
  const context = useContext(AppAuthContext);
  if (!context) {
    throw new Error('useAppAuth must be used within AppAuthContextProvider');
  }
  return context as AppAuthContextPropsType;
};

export interface AppAuthenticationServiceProviderPropsType {
  children: React.ReactNode;
  autoAuth?: boolean;
}

export type AppAuthenticationServiceProviderComponentPropsType = AppAuthenticationServiceProviderPropsType & {
  legacyUserInfo: ReturnType<typeof useLocalStorage<LegacyUserInfoType>>[0];
  setLegacyUserInfo: ReturnType<typeof useLocalStorage<LegacyUserInfoType>>[1];
  isLegacyUserInfoLoading: boolean;
  setIsLegacyUserInfoLoading: React.Dispatch<React.SetStateAction<boolean>>;
};

const AppAuthenticationServiceProviderComponent = (props: AppAuthenticationServiceProviderComponentPropsType) => {
  const { children, autoAuth, setLegacyUserInfo, legacyUserInfo, setIsLegacyUserInfoLoading, isLegacyUserInfoLoading } =
    props;
  const authContext = useAuth();
  const previousLegacyUserInfo = usePrevious(legacyUserInfo);

  useEffect(() => {
    if (!!legacyUserInfo && isLegacyUserInfoLoading) {
      setIsLegacyUserInfoLoading(false);
    }
    if (!legacyUserInfo && !!previousLegacyUserInfo) {
      void authContext.removeUser();
    }
  }, [legacyUserInfo]);

  // useEffect(() => {
  //   console.log({ authContext });
  //   if (authContext.user?.expired === true) {
  //     void authContext.signinSilent({state: state: {
  //       preAuthLocation: location.state?.preAuthLocation ?? location,
  //     },});
  //   }
  // }, [authContext.user?.expired]);

  const AppAuthContextObject: AppAuthContextPropsType = useMemo(
    () => ({
      ...authContext,
      isLoading: authContext.isLoading || isLegacyUserInfoLoading,
      isAuthenticated: authContext.isAuthenticated && !!legacyUserInfo,
      legacyUserInfo,
    }),
    [authContext, legacyUserInfo],
  );

  return <AppAuthContext.Provider value={AppAuthContextObject}>{children}</AppAuthContext.Provider>;
};

export const AppAuthenticationServiceProvider = (props: AppAuthenticationServiceProviderPropsType) => {
  const { children, autoAuth } = props;
  const [isLegacyUserInfoLoading, setIsLegacyUserInfoLoading] = useState<boolean>(false);
  const [legacyUserInfo, setLegacyUserInfo, removeLegacyUserInfo] = useLocalStorage<LegacyUserInfoType>({
    key: `oidc.legacyUserInfo:${CONSTANTS.APP_API_SESSION_ACCESS_URL}:${CONSTANTS.APP_API_CLIENT_ID}`,
    // defaultValue: null,
  });
  const onSignInCallback: AuthProviderProps['onSigninCallback'] = async (user) => {
    try {
      if (user instanceof User) {
        const response = await fetch(`${CONSTANTS.APP_API_SESSION_ACCESS_URL}/connect/userinfo`, {
          headers: {
            Authorization: `Bearer ${user.access_token ?? ''}`,
          },
        });
        if (!response.ok) {
          throw new Error('Sorry, you are not authorized to access this site');
        }
        const userInfo = await response.json();
        const decoded_access_token = jwtDecode<{ [key: string]: string }>(user.access_token);
        if (!userInfo || !withSearchAccessRole(decoded_access_token?.role)) {
          legacyUserInfo && removeLegacyUserInfo();
          throw new Error('Sorry, you are not authorized to access this site');
        }
        setLegacyUserInfo({
          ...(decoded_access_token.role ? { role: decoded_access_token.role } : {}),
          ...userInfo,
          picture: getGravatar(userInfo?.email, { size: '64' }),
        });
        setIsLegacyUserInfoLoading(false);
      }
    } catch (e) {
      setIsLegacyUserInfoLoading(false);
      throw new Error('Sorry, you are not authorized to access this site');
    }
  };

  const onRemoveUser = () => {
    removeLegacyUserInfo();
  };

  return (
    <AuthProvider {...oidcConfig} onSigninCallback={onSignInCallback} onRemoveUser={onRemoveUser}>
      <AppAuthenticationServiceProviderComponent
        autoAuth={autoAuth}
        legacyUserInfo={legacyUserInfo}
        setLegacyUserInfo={setLegacyUserInfo}
        setIsLegacyUserInfoLoading={setIsLegacyUserInfoLoading}
        isLegacyUserInfoLoading={isLegacyUserInfoLoading}
      >
        {children}
      </AppAuthenticationServiceProviderComponent>
    </AuthProvider>
  );
};
