import { Flex } from '@mantine/core';
import React from 'react';
import { useForm } from '@mantine/form';
import { useChatStore } from './ChatStoreProvider';
import { useDidMountEffect } from '../../../hooks';
import { SearchInput } from '../../../components';

export const ChatInput = () => {
  const { onReplyToChat, postReplyToChatStreamingId, editableUserMessage, setEditableUserMessage } = useChatStore();
  const chatForm = useForm<{ message: string }>({
    name: 'AiChatForm',
    initialValues: {
      message: editableUserMessage?.text || '',
    },
    validate: {
      message: (value) => {
        const isValid = value.replace(/\r\n|\r|\n/g, '').length >= 3;
        return isValid ? null : 'Name must have at least 3 letters';
      },
    },
    validateInputOnBlur: false,
    onValuesChange: (values) => {
      // console.log({ values });
    },
  });

  useDidMountEffect(() => {
    chatForm.setFieldValue('message', editableUserMessage?.text || '');
  }, [editableUserMessage?.text]);

  const handleSearch = async () => {
    if (!chatForm.validate().hasErrors) {
      await onReplyToChat(chatForm.values.message, editableUserMessage?.id).then(() => {
        setEditableUserMessage(null);
        chatForm.setFieldValue('message', '');
      });
    }
  };

  const onSpeechRecognitionChange = async (value: string) => {
    // if (value.length > 3) {
    //   chatForm.setFieldValue('message', value);
    // }
  };

  return (
    <Flex w="100%" gap={0} justify="space-between" align="center" direction="row" wrap="nowrap">
      <SearchInput
        key={chatForm.key('message')}
        {...chatForm.getInputProps('message')}
        placeholder="Message to ..."
        autoFocus
        onInputSubmit={handleSearch}
        loading={!!postReplyToChatStreamingId}
        inputBorderRadius={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
        }}
        onSpeechRecognitionChange={onSpeechRecognitionChange}
      />
    </Flex>
  );
};
