import { ActionIcon, NavLink, Portal, Title } from '@mantine/core';
import { IconMessagePlus } from '@tabler/icons-react';
import React from 'react';
import { NavLink as RouterNavLink } from 'react-router-dom';
import { useAppTheme } from '../themes';
import { useAppLayout } from '../layout/AppLayoutProvider';

export interface NewChatButtonProps {
  type: 'icon' | 'navLink';
}

export const NewSearchSessionButton: React.FC<NewChatButtonProps> = (props) => {
  const { type } = props;
  const { mantineTheme: theme, appColorScheme: colorScheme } = useAppTheme();
  const { handleNavbarStatusChange } = useAppLayout();
  const iconColor = colorScheme === 'light' ? theme.colors.dark[9] : theme.colors.gray[5];

  if (type === 'navLink') {
    return (
      <NavLink
        to="/"
        label={<Title order={4}>New search</Title>}
        component={RouterNavLink}
        leftSection={<IconMessagePlus />}
        active
        onClick={() => {
          void handleNavbarStatusChange({ isOpen: false, activeTab: 'filters' });
        }}
      />
    );
  }

  return (
    <ActionIcon variant="transparent" to="/" component={RouterNavLink}>
      <IconMessagePlus color="white" size={28} stroke={2} />
    </ActionIcon>
  );
};

export const NewSearchSessionButtonPortalRender: React.FC = (props) => {
  const { isMobileLayout } = useAppTheme();
  return (
    <>
      <Portal target="#new-chat-button-navbar--portal-wrapper">
        <NewSearchSessionButton type="navLink" />
      </Portal>
      {isMobileLayout && (
        <Portal target="#settings-menu-mobile--portal-wrapper">
          <NewSearchSessionButton type="icon" />
        </Portal>
      )}
    </>
  );
};
