import React from 'react';
import { Flex } from '@mantine/core';
import { v4 as uuidV4 } from 'uuid';
import { useSearchStore } from '../SearchStoreProvider';
import { SearchInput as DefaultSearchInput } from '../../../components';
import { useAppTheme } from '../../../themes';

export const SearchInput = () => {
  const { onSearch, sendSearchQueryStore, sendSearchQuery, searchForm } = useSearchStore();

  const handleSearch = async () => {
    await onSearch();
  };

  const onSpeechRecognitionChange = async (value: string) => {
    if (value.length > 3) {
      // await sendSearchQuery({
      //   variables: {
      //     input: { ...searchForm.values, id: uuidV4(), query: value },
      //   },
      // }).catch((error) => {
      //   console.warn('Search error', { error });
      // });
    }
  };

  return (
    <Flex w="100%" gap={5} justify="space-between" align="center" direction="row">
      <DefaultSearchInput
        key={searchForm.key('query')}
        {...searchForm.getInputProps('query')}
        placeholder="Input you search query ..."
        onInputSubmit={handleSearch}
        loading={sendSearchQueryStore.loading}
        onSpeechRecognitionChange={onSpeechRecognitionChange}
      />
    </Flex>
  );
};
