export const linkExtractor = /(\(|\\\[)\s*(\d{1,3}(\s*[,-]\s*\d{1,3})*)\s*(,|\)|\\\\]|$)/gi;

export type LinkSetType = {
  part: number;
  originalPart: string;
  linkNumber: number;
  linkSetNumber: number;
}[];

export type LinkOutputType = { linkSetNumber: number; start: number; end: number; text: string; links: LinkSetType };
export type LinkSetOutputType = LinkOutputType[];

export const getLinkSet = (
  text: string,
  linkSetNumber: number,
  linkSetOutput: LinkSetOutputType,
  lastLinkNumber: number,
  setLastLinkNumber: (linkNumber: number) => void,
): LinkSetType => {
  const parts: LinkSetType = [];
  const normalizedText = text.replace(/^\(/, '').replace(/\)$/, '').trim();
  let prevLinkNumber = lastLinkNumber;
  normalizedText
    .split(',')
    .map((s) => s.trim())
    .forEach((part, index) => {
      let clonedLinkNumber: number | null = null;
      linkSetOutput.every((linkSetItem) => {
        return linkSetItem.links.every((link) => {
          if (link.originalPart === part) {
            clonedLinkNumber = link.linkNumber;
            return false;
          }
          return true;
        });
      });
      if (part.includes('-')) {
        const [start, end] = part.split('-').map((s) => parseInt(s, 10));
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(start) || isNaN(end)) {
          // eslint-disable-next-line no-continue
          return;
        }
        for (let i = start; i <= end; i++) {
          const linkNumber = clonedLinkNumber ?? prevLinkNumber + 1;
          parts.push({
            part: i,
            originalPart: part,
            linkNumber,
            linkSetNumber,
          });
          if (!clonedLinkNumber) {
            prevLinkNumber += 1;
          }
        }
      } else {
        const parsedPart = parseInt(part, 10);
        // eslint-disable-next-line no-restricted-globals
        if (isNaN(parsedPart)) {
          // eslint-disable-next-line no-continue
          return;
        }
        const linkNumber = clonedLinkNumber ?? lastLinkNumber + 1;

        parts.push({
          part: parsedPart,
          originalPart: part,
          linkNumber,
          linkSetNumber,
        });
        if (!clonedLinkNumber) {
          prevLinkNumber += 1;
        }
      }
    });
  setLastLinkNumber(prevLinkNumber);
  return parts;
};

export const currentLinksSetNumbersRender = (links?: LinkSetType): string => {
  if (!links || (links && links?.length < 1)) {
    return '';
  }
  const startSection = '( ';
  const endSection = ' )';
  return `
    <sup>${startSection}
      ${links.map((r, i, arr) => {
        const delimiter = arr.length === i + 1 ? '' : ', ';
        return `<span>${r.linkNumber}${delimiter}</span>`;
      })}
      ${endSection}
    </sup>`;
};
