import React from 'react';
import { ActionIcon, Avatar, Divider, MantineColorScheme, Menu, NavLink } from '@mantine/core';
import { IconKey, IconLogout, IconUserEdit } from '@tabler/icons-react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CONSTANTS } from '../config';
import { LegacyUserInfoType, useAppAuth } from '../services';

export const UserAvatar: React.FC = () => {
  const { legacyUserInfo } = useAppAuth();
  return <Avatar radius="xl" size={28} src={legacyUserInfo?.picture} alt="it's me" />;
};

export interface ILoginSectionProps {
  type?: 'dropdown' | 'accordion' | 'list';
}

export const LoginSection: React.FC<ILoginSectionProps> = (props) => {
  const { type = 'dropdown' } = props;
  const { removeUser, legacyUserInfo } = useAppAuth();
  const navigate = useNavigate();
  const location = useLocation();
  if (type === 'dropdown') {
    return (
      <Menu shadow="md" width={200} disabled={!legacyUserInfo}>
        <Menu.Target>
          <ActionIcon
            variant="subtle"
            radius="xl"
            disabled={!legacyUserInfo}
            styles={{
              root: {
                borderColor: 'transparent',
                backgroundColor: 'transparent',
              },
            }}
          >
            <Avatar radius="xl" size={28} src={legacyUserInfo?.picture} alt="it's me" />
          </ActionIcon>
        </Menu.Target>
        <Menu.Dropdown>
          <Menu.Label>{legacyUserInfo?.name}</Menu.Label>
          <Menu.Item
            onClick={() => {
              const url = `${CONSTANTS.APP_API_SESSION_PROVIDER}/profile`;
              window.open(url, '_blanc');
            }}
            leftSection={<IconUserEdit size="1rem" stroke={2} />}
          >
            Profile
          </Menu.Item>
          <Menu.Item
            onClick={() => {
              navigate('/auth', { state: { preAuthLocation: { ...location } } });
            }}
            leftSection={<IconKey size="1rem" stroke={2} />}
          >
            Auth Page
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item
            color="red"
            onClick={() => {
              void removeUser();
            }}
            leftSection={<IconLogout size="1rem" stroke={2} />}
          >
            Log Out
          </Menu.Item>
        </Menu.Dropdown>
      </Menu>
    );
  }
  if (type === 'list') {
    return (
      <>
        <NavLink
          onClick={() => {
            const url = `${CONSTANTS.APP_API_SESSION_PROVIDER}/profile`;
            window.open(url, '_blanc');
          }}
          leftSection={<IconUserEdit size="1rem" stroke={2} />}
          label="Profile"
        />
        <NavLink
          onClick={() => {
            navigate('/auth', { state: { preAuthLocation: { ...location } } });
          }}
          leftSection={<IconKey size="1rem" stroke={2} />}
          label="Auth Page"
        />
        <Divider />
        <NavLink
          color="red"
          active
          onClick={() => {
            void removeUser();
          }}
          leftSection={<IconLogout size="1rem" stroke={2} />}
          label="Log Out"
        />
      </>
    );
  }
  return null;
};
