import React from 'react';
import { v4 as uuidV4 } from 'uuid';
import { SearchInput } from '../../../../components';
import { useNewSearchStore } from './NewSearchStoreProvider';

export const NewSearchInput = () => {
  const { sendSearchQueryStore, sendSearchQuery, searchForm, onSearch } = useNewSearchStore();

  const handleSearch = async () => {
    await onSearch();
  };

  const onSpeechRecognitionChange = async (value: string) => {
    if (value.length > 3) {
      // await sendSearchQuery({
      //   variables: {
      //     input: { ...searchForm.values, id: uuidV4(), query: value },
      //   },
      // }).catch((error) => {
      //   console.warn('Search error', { error });
      // });
    }
  };

  return (
    <SearchInput
      key={searchForm.key('query')}
      {...searchForm.getInputProps('query')}
      autoFocus
      placeholder="Input you search query ..."
      onInputSubmit={handleSearch}
      loading={sendSearchQueryStore.loading}
      onSpeechRecognitionChange={onSpeechRecognitionChange}
    />
  );
};
