import { DependencyList, useEffect, useRef } from 'react';

export const useDebouncedEffect = <T extends (...args: any[]) => any>(
  callback: T,
  deps: DependencyList,
  delay?: number,
) => {
  const didMountRef = useRef<boolean>(true);

  useEffect(() => {
    if (didMountRef.current) {
      didMountRef.current = false;
      return;
    }

    const handler = setTimeout(() => {
      callback?.();
    }, delay);

    return () => clearTimeout(handler);
  }, [delay, ...deps]);
};
