import React, { ReactNode, useEffect, useMemo, useState } from 'react';
import { Anchor, Grid, HoverCard, Loader, rem, Stack, Text } from '@mantine/core';
import { useInterval } from '@mantine/hooks';
import { ChatMessageDto, SemanticSearchResultDto } from '../../../services/graphql/apolloAppClient';
import { useSearchHistoryEntryStore } from '../SearchHistoryEntryStoreProvider';
import { chatMessageTextStyles } from './ChatMessage';
import { useChatStore } from './ChatStoreProvider';
import { useAppTheme } from '../../../themes';
import { EgwSource, InlineDotsLoader } from '../../../components';
import {
  currentLinksSetNumbersRender,
  getLinkSet,
  linkExtractor,
  LinkOutputType,
  LinkSetOutputType,
  LinkSetType,
} from './utils';

export type ChatMessageLinkHandlerType = (snippet: SemanticSearchResultDto) => void;

export type ChatSystemMessageTextRenderPropsType = {
  message: ChatMessageDto;
  onClick?: ChatMessageLinkHandlerType;
};

export const ChatSystemMessageTextRender = (props: ChatSystemMessageTextRenderPropsType) => {
  const { generateReplyToUserMessageStreamingId, postReplyToChatStreamingId } = useChatStore();
  const { message, onClick } = props;
  const { appColorScheme } = useAppTheme();
  const [hoveredLinkId, setHoveredLinkId] = useState<number | null>(null);

  const messageText = message?.text?.replace(/\n/g, '<br />');
  let match;
  const output: ReactNode[] = [];
  const matches: RegExpExecArray[] = [];

  // eslint-disable-next-line no-cond-assign
  while ((match = linkExtractor.exec(messageText))) {
    matches.push(match);
  }
  let prevPosition = 0;
  let linkSetNumber = 0;
  let lastLinkNumber = 0;

  const setLastLinkNumber = (linkNumber: number) => {
    lastLinkNumber = linkNumber;
  };
  const linkSetOutput: LinkSetOutputType = [];

  for (const match of matches) {
    const currentLinkSet: LinkOutputType = {
      start: match.index,
      end: match.index + match[0].length,
      text: messageText.slice(match.index, match.index + match[0].length),
      linkSetNumber: linkSetNumber + 1,
      links: getLinkSet(
        messageText.slice(match.index, match.index + match[0].length),
        linkSetNumber + 1,
        linkSetOutput,
        lastLinkNumber,
        setLastLinkNumber,
      ),
    };
    const innerHtml = `${messageText.slice(prevPosition, match.index)}${currentLinksSetNumbersRender(
      currentLinkSet?.links,
    )}${matches.indexOf(match) + 1 === matches.length ? messageText.slice(match.index + match[0].length) : ''}`;

    output.push(
      <React.Fragment key={`t${match.index}`}>
        <Text
          id={`text_${message.type}_${message.id}_${match.index}`}
          maw="100%"
          size="sm"
          dangerouslySetInnerHTML={{
            __html: innerHtml,
          }}
          component="span"
          styles={chatMessageTextStyles}
        />
      </React.Fragment>,
    );
    linkSetNumber += 1;
    linkSetOutput.push(currentLinkSet);
    prevPosition = match.index + match[0].length;
  }

  return (
    <>
      {output.length > 0 ? (
        output
      ) : (
        <Text
          maw="100%"
          size="sm"
          dangerouslySetInnerHTML={{ __html: messageText }}
          component="span"
          styles={chatMessageTextStyles}
        />
      )}
      {generateReplyToUserMessageStreamingId === message.id || postReplyToChatStreamingId === message.id ? (
        // <Loader
        //   styles={{ root: { display: 'inline-flex', paddingLeft: 3 } }}
        //   size="sm"
        //   color={appColorScheme === 'dark' ? 'white' : 'black'}
        //   type="dots"
        // />
        <InlineDotsLoader />
      ) : (
        <ChatMessageSources linkSetOutput={linkSetOutput} onClick={onClick} hoveredLinkId={hoveredLinkId} />
      )}
    </>
  );
};

export interface ChatMessageSourcesProps {
  linkSetOutput: LinkSetOutputType;
  hoveredLinkId: number | null;
  onClick?: ChatMessageLinkHandlerType;
}
export interface ChatMessageSourceLinkProps {
  index: number;
  partNumber: number;
  hoveredLinkId: number | null;
  onClick?: ChatMessageLinkHandlerType;
}
export const ChatMessageSourceLink: React.FC<ChatMessageSourceLinkProps> = (props) => {
  const { index, partNumber, hoveredLinkId, onClick } = props;
  const { searchHistoryEntryStore } = useSearchHistoryEntryStore();
  const snippet = searchHistoryEntryStore?.data?.query?.searchResults
    ?.map((r) => r)
    .find((r) => r.order === partNumber);
  const handleClick = useMemo(
    () => (e: React.MouseEvent) => {
      snippet && onClick?.(snippet);
      e.stopPropagation();
      e.preventDefault();
    },
    [onClick, snippet],
  );
  if (!snippet) {
    return null;
  }

  return (
    <HoverCard
      width={280}
      withArrow
      clickOutsideEvents={['mouseup', 'touchend']}
      openDelay={0}
      closeDelay={0}
      withinPortal={false}
    >
      <HoverCard.Target>
        <Anchor
          component={snippet?.uri ? 'a' : 'span'}
          underline={hoveredLinkId === index ? 'always' : 'hover'}
          href={snippet?.uri}
          target="_blank"
          rel="noreferrer"
          size="xs"
          styles={{
            root: {
              fontFamily: 'Roboto',
              fontWeight: 400,
              width: '100%',
              display: 'inline-block',
              verticalAlign: 'middle',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            },
          }}
        >
          {`${index})`}
          <EgwSource size="xs" style={{ marginLeft: rem(1), marginRight: rem(1) }} />
          {snippet.referenceCode}
        </Anchor>
      </HoverCard.Target>
      <HoverCard.Dropdown>
        <Text component="span" size="sm" styles={chatMessageTextStyles}>
          {snippet.snippet}
        </Text>
      </HoverCard.Dropdown>
    </HoverCard>
  );
};

export const ChatMessageSources: React.FC<ChatMessageSourcesProps> = (props) => {
  const { linkSetOutput, hoveredLinkId, onClick } = props;

  const linkParts = useMemo(() => {
    const parts: number[] = [];
    const links: LinkSetType = [];

    linkSetOutput.forEach((linkSetItem) => {
      linkSetItem.links.forEach((link) => {
        if (!parts.includes(link.part)) {
          links.push(link);
        }
        parts.push(link.part);
      });
    });
    return links;
  }, [linkSetOutput]);

  return (
    <>
      {linkParts?.length > 0 && (
        <Stack p={10} gap={10} w="100%">
          <Text
            component="span"
            styles={{
              root: {
                fontFamily: 'Roboto',
                fontWeight: 400,
              },
            }}
          >
            Sources:
          </Text>
          <Grid
            mx={5}
            styles={{
              col: {
                padding: 0,
              },
            }}
          >
            {linkParts.map((link) => (
              <Grid.Col key={link.part} span={{ base: 12, md: 6, lg: 3 }}>
                <ChatMessageSourceLink
                  hoveredLinkId={hoveredLinkId}
                  index={link.linkNumber}
                  partNumber={link.part}
                  onClick={onClick}
                />
              </Grid.Col>
            ))}
          </Grid>
        </Stack>
      )}
    </>
  );
};
