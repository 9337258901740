import React from 'react';
import { Flex, Group } from '@mantine/core';
import { LoginSection } from '../../components';
import { AppThemeSwitcher, useAppTheme } from '../../themes';

export interface IAppSettingsSectionProps {
  isAuthenticated?: boolean;
}
export const AppSettingsSection: React.FC = () => {
  const { isMobileLayout } = useAppTheme();
  return (
    <Flex h="100%" gap="var(--mantine-spacing-xs)" justify="space-between" align="center" direction="row" wrap="nowrap">
      <Group id="settings-menu-mobile--portal-wrapper" gap="var(--mantine-spacing-xs)" wrap="nowrap" />
      {!isMobileLayout && (
        <Group gap="var(--mantine-spacing-xs)" wrap="nowrap">
          <AppThemeSwitcher />
          <LoginSection />
        </Group>
      )}
    </Flex>
  );
};
