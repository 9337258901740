import { ActionIcon, Alert, Avatar, Flex, Group, Text, TextProps, Title } from '@mantine/core';
import { IconEdit, IconEditOff, IconRefresh } from '@tabler/icons-react';
import React from 'react';
import {
  ChatMessageDto,
  ChatMessageType,
  RatingDto,
  useVoteForChatMessageMutation,
} from '../../../services/graphql/apolloAppClient';
import { ReactComponent as ChatLogo } from '../../../assets/images/egw-logo.svg';
import { DateTimeNormalizeRender, VoteSection } from '../../../components';
import { useAppAuth } from '../../../services';
import { useChatStore } from './ChatStoreProvider';
import { useSearchHistoryEntryStore } from '../SearchHistoryEntryStoreProvider';
import { ChatSystemMessageTextRender } from './ChatSystemMessageTextRender';
import { useAppTheme } from '../../../themes';

export interface IChatMessageProps {
  message: ChatMessageDto;
}

export const chatMessageTextStyles: TextProps['styles'] = {
  root: {
    padding: 0,
    margin: 0,
    border: 'none',
    outline: 'none',
    textWrap: 'wrap',
    wordWrap: 'break-word',
    wordBreak: 'break-word',
    color: 'var(--text-color)',
    fontSize: 'var(--text-fz, var(--mantine-font-size-md))',
    fontWeight: 400,
    lineHeight: 'var(--_text-line-height, var(--text-lh, var(--mantine-line-height-md)))',
    textDecoration: 'none',
  },
};

export const ChatMessage = (props: IChatMessageProps) => {
  const { message } = props;
  const { legacyUserInfo } = useAppAuth();
  const { mantineTheme: theme } = useAppTheme();

  const messageOrientation = message.type === ChatMessageType.System ? 'left' : 'right';
  const color = messageOrientation === 'left' ? theme.primaryColor : 'teal';
  return (
    <Flex
      p={0}
      gap={3}
      w="100%"
      wrap="nowrap"
      justify="flex-start"
      align="flex-start"
      direction={messageOrientation === 'right' ? 'row-reverse' : 'row'}
    >
      <Avatar
        bg={theme.primaryColor}
        radius="xl"
        size="sm"
        src={messageOrientation === 'right' ? legacyUserInfo?.picture : undefined}
        alt={messageOrientation === 'right' ? legacyUserInfo?.name || "it's me" : 'ChatEGW'}
      >
        {messageOrientation === 'left' && <ChatLogo style={{ height: 26.67, width: 40, color: 'white' }} />}
      </Avatar>
      <Flex
        styles={{ root: { maxWidth: 'calc(100% - 64px)' } }}
        direction="column"
        align={messageOrientation === 'left' ? 'flex-start' : 'flex-end'}
        gap={0}
      >
        <Title order={6}>{messageOrientation === 'right' ? legacyUserInfo?.name || 'User' : 'AI Assistant'}</Title>
        <Alert
          color={color}
          p={5}
          variant="light"
          w="100%"
          styles={{
            body: {
              width: '100%',
            },
          }}
          classNames={{
            message: `${
              messageOrientation === 'left'
                ? `chat-message-text-wrapper_${message.id}`
                : `user-message-text-wrapper_${message.id}`
            }`,
          }}
        >
          {messageOrientation === 'left' ? (
            <ChatSystemMessageTextRender message={message} />
          ) : (
            <Text
              id={`text_${message.type}_${message.id}`}
              maw="100%"
              size="sm"
              component="span"
              styles={chatMessageTextStyles}
            >
              {message.text}
            </Text>
          )}
        </Alert>
        <Flex
          w="100%"
          justify="space-between"
          align="center"
          direction={messageOrientation === 'right' ? 'row-reverse' : 'row'}
          wrap="wrap"
          gap={3}
          p={5}
        >
          <Text size="xs" c="dimmed" styles={{ root: { textAlign: messageOrientation, padding: 0 } }}>
            <DateTimeNormalizeRender date={message.createdAt} />
          </Text>
          {messageOrientation === 'right' && <EditActionView message={message} />}
          {messageOrientation === 'left' && (
            <Flex gap={10} wrap="nowrap" justify="start" align="center">
              <MessageVoteView rating={message.rating} messageId={message.id} />
              <ReloadActionView messageId={message.id} />
            </Flex>
          )}
        </Flex>
      </Flex>
    </Flex>
  );
};

const ReloadActionView = ({ messageId }: { messageId: IChatMessageProps['message']['id'] }) => {
  const { onGenerateReplyToUserMessage } = useChatStore();

  return (
    <ActionIcon
      // variant="default"
      variant="subtle"
      color="gray"
      styles={{
        root: {
          border: 'none',
        },
      }}
      // size={rem('1em')}
      onClick={() => {
        onGenerateReplyToUserMessage(messageId);
      }}
    >
      <IconRefresh />
    </ActionIcon>
  );
};

const EditActionView = ({ message }: { message: IChatMessageProps['message'] }) => {
  const { messages, editableUserMessage, setEditableUserMessage } = useChatStore();
  const isFirstMessage = messages.findIndex((m) => m.id === message.id) === 0;

  const handleEdit = () => {
    setEditableUserMessage(message);
  };

  const handleCancel = () => {
    setEditableUserMessage(null);
  };
  return (
    <div>
      {!isFirstMessage && (
        <Group gap={5}>
          <ActionIcon
            variant="subtle"
            color="gray"
            styles={{
              root: {
                border: 'none',
              },
            }}
            onClick={editableUserMessage?.id === message.id ? handleCancel : handleEdit}
          >
            {editableUserMessage?.id !== message.id ? <IconEdit /> : <IconEditOff color="red" />}
          </ActionIcon>
        </Group>
      )}
    </div>
  );
};

const MessageVoteView = ({
  rating,
  messageId,
}: {
  rating: RatingDto;
  messageId: IChatMessageProps['message']['id'];
}) => {
  const { searchHistoryEntryStore } = useSearchHistoryEntryStore();
  const [voteForChatMessage] = useVoteForChatMessageMutation({
    onCompleted: () => {
      void searchHistoryEntryStore.refetch();
    },
    onError: (error) => {
      console.log({ error });
    },
  });
  return (
    <VoteSection
      onVote={(vote) =>
        voteForChatMessage({
          variables: {
            input: {
              messageId,
              vote,
            },
          },
        })
      }
      rating={rating}
    />
  );
};
